let $header = $('header');
let $padding = 1.5*16;
let $mainColor = $header.attr('data-color');
let $hero = $('.hero');
let $heroTextLogoColorBlack = $hero.find('.content-left .logo-content.color-black') ?? '';
let $heroTextColorBlack = $hero.find('.content-left .text-content.color-black') ?? '';

if($hero.length > 0) {
    if($heroTextColorBlack.length > 0 || $heroTextLogoColorBlack.length > 0) {
        $header.find('.main-menu .site-logo svg path').css({
            'fill': '#000'
        })
        $header.find('.main-menu .site-logo svg rect').css({
            'fill': '#000'
        })
    }
}
else {
    $header.find('.main-menu .site-logo svg path').css({
        'fill': '#000'
    })
    $header.find('.main-menu .site-logo svg rect').css({
        'fill': '#000'
    })
}

$(window).scroll(function (e) {
    let scrollTop = $(this).scrollTop();
    
    if (scrollTop > $padding) {
        $header.addClass('sticky')
        if(!($header.hasClass('consept-menu') && $(window).width() < 768)) {
            if($mainColor.length > 0) {
                $header.css({
                    'background-color': $mainColor
                });
            }
            else {
                $header.css({
                    'background-color': '#fff'
                })
                $header.find('.main-menu .site-logo svg path').css({
                    'fill': '#000'
                })
                $header.find('.main-menu .site-logo svg rect').css({
                    'fill': '#000'
                })
            }
        }
    }
    else {
        $header.removeClass('sticky')
        $header.css({
            'background-color': 'transparent'
        });
        if($heroTextColorBlack.length > 0 || $heroTextLogoColorBlack.length > 0 || $('body').hasClass('error404')) {
            $header.find('.main-menu .site-logo svg path').css({
                'fill': '#000'
            })
            $header.find('.main-menu .site-logo svg rect').css({
                'fill': '#000'
            })
        }
        else {
            $header.find('.main-menu .site-logo svg path').css({
                'fill': '#fff'
            })
            $header.find('.main-menu .site-logo svg rect').css({
                'fill': '#fff'
            })
        }
    }
});