const popup = document.querySelector('.campaign-box');

if(popup) {
    let dismissed = localStorage.getItem("dismissed");
    let closeBtn = popup.querySelector('#close-popup');
    let expandBtn = popup.querySelector('.expand-icon');
    
    if(!dismissed){
        popup.classList.remove("hide");
    }

    closeBtn.addEventListener('click', function() {
        popup.classList.add('hide');
        localStorage.setItem("dismissed", true);
    });

    expandBtn.addEventListener('click', function() {
        popup.classList.remove('hide');
        localStorage.removeItem("dismissed");
    });
}